$blue: #0b6dc7;
$secondary: #f0f2f6;
$success: #168e32;
$info: #12a7d0;
$input-btn-padding-x: 1rem;
$font-size-base: 1rem;
$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.75;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base * 1.1;
$h6-font-size: $font-size-base;
$btn-padding-x-lg: 1.25rem;
$input-height-inner: 2.5rem;
$input-padding-y: 0.5rem;
$input-padding-x: 0.75rem;
$input-padding-y-sm: 0.25rem;
$input-padding-x-sm: 0.5rem;
$form-group-margin-bottom: 1.5rem;
$tooltip-bg: #042f56;
$tooltip-font-size: 0.8rem;

@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/root';
@import '../../node_modules/bootstrap/scss/reboot';
@import '../../node_modules/bootstrap/scss/type';
// @import '../../node_modules/bootstrap/scss/images';
@import '../../node_modules/bootstrap/scss/code';
@import '../../node_modules/bootstrap/scss/grid';
@import '../../node_modules/bootstrap/scss/tables';
@import '../../node_modules/bootstrap/scss/forms';
@import '../../node_modules/bootstrap/scss/buttons';
@import '../../node_modules/bootstrap/scss/transitions';
@import '../../node_modules/bootstrap/scss/dropdown';
@import '../../node_modules/bootstrap/scss/button-group';
@import '../../node_modules/bootstrap/scss/input-group';
// // @import '../../node_modules/bootstrap/scss/custom-forms';
@import '../../node_modules/bootstrap/scss/nav';
// // @import '../../node_modules/bootstrap/scss/navbar';
// // @import '../../node_modules/bootstrap/scss/card';
@import '../../node_modules/bootstrap/scss/breadcrumb';
// // @import '../../node_modules/bootstrap/scss/pagination';
@import '../../node_modules/bootstrap/scss/badge';
// // @import '../../node_modules/bootstrap/scss/jumbotron';
@import '../../node_modules/bootstrap/scss/alert';
@import '../../node_modules/bootstrap/scss/progress';
// // @import '../../node_modules/bootstrap/scss/media';
// // @import '../../node_modules/bootstrap/scss/list-group';
@import '../../node_modules/bootstrap/scss/close';
@import '../../node_modules/bootstrap/scss/modal';
@import '../../node_modules/bootstrap/scss/tooltip';
// // @import '../../node_modules/bootstrap/scss/popover';
// // @import '../../node_modules/bootstrap/scss/carousel';
@import '../../node_modules/bootstrap/scss/utilities';
// @import '{}/node_modules/bootstrap/scss/print';

@import './_validation';
@import './_animations';
@import './csspie';
@import '../../node_modules/@fortawesome/fontawesome/styles.css';
@import '../../node_modules/react-day-picker/lib/style.css';
@import '../../node_modules/rc-slider/assets/index.css';

// Global variables
:root {
  --border-color: #{$border-color};
  --link-hover-color: #{$link-hover-color};
  --bg-color: #f0f2f6;
  --even-row-bg: #fbfbfd;
  --text-muted-blue: #7994ad;
  --text-muted: #88929c;
  --text-panel: #565b61;
  --page-gutter: 1rem;
  --heading-color: #40566f;
}

.text-muted {
  color: var(--text-muted);
}

.text-muted-blue {
  color: var(--text-muted-blue);
}

.text-smaller {
  font-size: 0.9375rem;
}

.text-small {
  font-size: 0.875rem;
}

.text-smallest {
  font-size: 0.8125rem;
}

.text-bigger {
  font-size: 1.125rem;
}

.col-divider {
  width: 1px;
  background: var(--border-color);
  margin: 0 1rem;
}

@include media-breakpoint-up(lg) {
  :root {
    --page-gutter: 2.5rem;
  }
}

@include media-breakpoint-down(sm) {
  h1 {
    font-size: 1.75rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1.25rem;
  }
  h4 {
    font-size: 1.1rem;
  }
  h5 {
    font-size: 1.1rem;
  }
  h6 {
    font-size: 1rem;
  }
}

.nomargin {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
  margin-bottom: 1rem;
}

h4,
h5,
h6 {
  margin-bottom: 0.25rem;
}

.form-group > label {
  font-weight: 500;
}
.form-check-label {
  font-weight: normal;
}

// Layout
html,
body,
#root {
  background: var(--bg-color);
  min-height: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

// Typography

// Style overrides
.dropdown-menu {
  border-color: #d3d8dc;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.1);

  .dropdown-item {
    color: #07457f;
    &:hover {
      color: var(--primary);
    }
    &:active,
    &.active {
      color: #fff;
    }
  }
  .svg-inline--fa {
    color: #9eaebd;
  }
}
.dropdown-item {
  padding: 0.4rem 1.5rem 0.4rem 1rem;
}

.modal-backdrop.show {
  opacity: 0.9;
  background-color: #111925;
}
hr {
  border-top: 2px solid rgb(240, 242, 246);
}
.table-striped tbody tr:nth-of-type(odd) {
  background: none;
}
@media screen {
  .table-striped tbody tr:nth-of-type(even) {
    background-color: var(--even-row-bg);
  }
}

$btn-alt-color: #395e8a !default;
$btn-alt-active-color: var(--primary) !default;
$btn-alt-bg: #fff !default;

.btn-alt {
  color: $btn-alt-color;
  border-color: var(--border-color);
  background: $btn-alt-bg;

  &:hover,
  &.hover {
    color: var(--primary);
    border-color: $btn-alt-active-color;
  }

  &:focus,
  &.focus {
    border-color: $btn-alt-active-color;
    box-shadow: none;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    color: $gray-700;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $btn-alt-active-color;
    border-color: $btn-alt-active-color;

    &:focus {
      border-color: $btn-alt-active-color;
    }
  }
}
.btn {
  .fa-btn-pre {
    margin-right: 4px;
  }
}
.btn-sm {
  .fa-btn-pre {
    margin-right: 3px;
  }
}

.btn-md {
  font-size: 0.875rem;
}

.btn-secondary {
  color: #676767;
}

div > .react-select__control {
  font-size: 0.875rem;
  background: #fff;
  border-color: #ced4da;
}

// Ace editor
.ace_editor {
  min-height: 200px;
}
.ace_editor.ace-tm {
  background-color: #fcfcfd;
}

.fastest {
  animation-duration: 0.25s;
}

[data-react-beautiful-dnd-drag-handle='0'] {
  cursor: -webkit-grab !important;
  cursor: grab !important;
}

.quick-stats {
  max-width: 900px;
  margin: 0 auto 2rem auto;
  text-align: center;
  .metric {
    font-size: 2rem;
    color: var(--primary);
    font-weight: 600;
  }
}

.react-select__menu {
  z-index: 900000 !important;
}

.filter-alert {
  padding: 0.5rem;
  font-size: 0.8rem;
  text-align: center;
  background: #ffffd8;
  color: #5d5d0a;
  border-bottom: 1px solid var(--border-color);
}

.tag-color-blue {
  background: var(--blue);
}
.tag-color-green {
  background: var(--green);
}
.tag-color-cyan {
  background: var(--cyan);
}
.tag-color-yellow {
  background: var(--yellow);
}
.tag-color-orange {
  background: var(--orange);
}
.tag-color-red {
  background: var(--red);
}
.tag-color-preview {
  width: 0.75rem;
  height: 0.75rem;
  vertical-align: middle;
  display: inline-block;
  border-radius: 100%;
  margin-right: 0.25rem;
}

.company-tag {
  position: relative;
  display: inline-block;
  border-radius: 3px;
  padding: 0.1rem 0.4rem 0.1rem 0.4rem;
  margin: 0 0.2rem;
  vertical-align: middle;
  color: #fff;

  .remove {
    display: inline-block;
    vertical-align: middle;
    border: 0;
    background: 0;
    padding: 0;
    margin-left: 0.1rem;
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    &:hover {
      color: rgba(255, 255, 255, 1);
    }
  }
}

@media print {
  html,
  body,
  #root {
    background: none;
    overflow: visible;
    height: auto;
  }
}


.persona-age-chartjs-tooltip {
  background: rgba(0,0,0,0.8);
  border-radius: 6px;
  padding: 0.8em 1em;
  font-size: 13px;
  color: #fff;
}