.is-invalid {
  > label,
  .form-check-label,
  .form-radio-label,
  > .form-text {
    color: theme-color('danger') !important;
  }
  .invalid-feedback {
    display: block;
    font-weight: 600;
    margin-top: 0.5rem;
    font-size: 0.875rem;
  }
  > div > .react-select__control {
    color: theme-color('danger') !important;
    border-color: theme-color('danger') !important;
  }
}
.is-valid {
  > label,
  > .form-check > label,
  > .form-text {
    color: theme-color('success') !important;
  }
  > div > .react-select__control {
    color: theme-color('success') !important;
    border-color: theme-color('success') !important;
  }
}
